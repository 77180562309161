var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.workField.workFieldImagePath,
            _vm.workField.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.workField.workFieldImageIsDefault &&
          _vm.checkPrivilege(_vm.hasWorkFieldDeleteImage())},on:{"changeValue":function($event){_vm.workField.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomRadioButton',{attrs:{"className":'col-md-12',"id":(_vm.id + "-placeTypeToken"),"value":_vm.workField.placeTypeToken,"options":_vm.placeTypeTokenOptions,"title":_vm.$t('Places.type'),"imgName":'type.svg'},on:{"changeValue":function($event){_vm.workField.placeTypeToken = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.workField.fullCode,"title":_vm.$t('WorkFields.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.workField.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-workFieldNameAr"),"errors":_vm.errors_workFieldNameAr,"value":_vm.workField.workFieldNameAr,"title":_vm.$t('WorkFields.nameAr'),"imgName":'workFields.svg'},on:{"changeValue":function($event){_vm.workField.workFieldNameAr = $event;
            _vm.$v.workField.workFieldNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-workFieldNameEn"),"errors":_vm.errors_workFieldNameEn,"value":_vm.workField.workFieldNameEn,"title":_vm.$t('WorkFields.nameEn'),"imgName":'workFields.svg'},on:{"changeValue":function($event){_vm.workField.workFieldNameEn = $event;
            _vm.$v.workField.workFieldNameEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-workFieldNotes"),"value":_vm.workField.workFieldNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.workField.workFieldNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }